import axios from 'axios'
import config from './config'
import _ from 'lodash'

class Api {
    static baseUrlFoto = Api.getBaseUrl() + 'foto/'
    static baseUrlThumb1 = Api.getBaseUrl() + 'thumb1/'
    static baseUrlThumb2 = Api.getBaseUrl() + 'thumb2/'

    static _toko = null

    static setToko(toko) {
        this._toko = toko
        this.baseUrlFoto = Api.getBaseUrl() + 'foto/'
        this.baseUrlThumb1 = Api.getBaseUrl() + 'thumb1/'
        this.baseUrlThumb2 = Api.getBaseUrl() + 'thumb2/'
    }

    static getNamaToko() {
        return this.getToko()?.nama
    }

    static getDaftarToko() {
        return config.daftar_toko
    }

    static getTokoById(id) {
        let daftar = config.daftar_toko
        return _.find(daftar, { id })
    }

    static getToko() {
        return this._toko
        // return this.getTokoById(config.toko_id)
    }

    static getTokoId() {
        return this.getToko()?.id
    }


    static getShowHargaGrosir() {
        return this.getToko()?.show_harga_grosir
    }

    static getGudangId() {
        return parseInt(localStorage.getItem("gudangId") || -1)
    }

    static setGudangId(gudangId) {
        localStorage.setItem("gudangId", gudangId + "")
    }

    static getBaseUrl() {
        return this.getToko()?.api_url
    }

    static getLogo() {
        return `/img/logo/${Api.getToko()?.id?.toLowerCase()}.png`
    }

    static getSideImage() {
        return '/img/sidebar/sidebar.jpg'
    }

    static async login(user, password) {
        let api
        try {
            let res = await axios.post(Api.getBaseUrl() + 'login2', {
                user,
                password
            })
            api = res.data
        } catch (er) {
            console.error(er)
        }
        return api
    }

    static isLoggedIn() {
        let token = localStorage.getItem("token") || ""
        return token.length > 0
    }

    static logout() {
        localStorage.setItem("token", "")
    }

    static setToken(token, user) {
        localStorage.setItem("token", token)
        localStorage.setItem("user", user)
    }

    static getUser() {
        return localStorage.getItem("user") || ""
    }

    static isNeedLogin() {
        return Api.getShowHargaGrosir()
    }

    static async getHomeData(gudangId) {
        let api
        try {
            let res = await axios.post(Api.getBaseUrl() + 'home2', {
                gudangid: gudangId
            })
            api = res.data
        } catch (er) {
            console.error(er)
        }
        return api
    }

    static async getDaftarBarang(cari, kategoriId, brandId, skip, take, sort = 'created desc', gudangId = -1) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'caribarang', { cari, kategoriId, brandId, skip, take, sort, gudangid: gudangId })
        api = res.data
        return api
    }

    static async getDaftarKategori() {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'kategori')
        api = res.data
        return api
    }

    static async getDaftarFooter(gudang_id) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'footers', { gudang_id })
        api = res.data
        return api
    }

    static async getBarang(id) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'detailbarang/' + id)
        api = res.data
        return api
    }

    static async getDaftarGudang(toko_id) {
        let ret = []
        let res = await axios.post(Api.getBaseUrl() + 'daftargudang', { toko_id })
        ret = res.data
        return ret
    }
}

export default Api